const $useSettingsPackSharedState = () => {
  const isCreated = ref(false)

  return {
    isCreated,
  }
}

export const useSettingsPackSharedState = createSharedComposable(
  $useSettingsPackSharedState
)
